.section-work{
    

}

.work-list-carousel{
    ul{
        display:flex;
        flex-flow:row nowrap;
        margin:0 -24px;
    }
    li{
        width:120px;
        height:200px;
        display:flex;
        margin:0 24px;

        a{
           margin:0 0px;
            display:flex;
            align-items:center;
        }
        svg{
            width:100%;
            path, polygon{
                fill:rgba($color-font-base,0.5);
            }
        }
    }
}

.work-list{
    //margin-top:12vw;
    
}
.work-item{
    height:100vh;
    position:relative;
    margin-bottom:0px;
    //padding-top:92px;
    margin-top:0vh;
    margin-bottom:0vh;
    @media only screen and (max-width:$br-tablet-min){
        //max-height:300px;
    }
    .work-item-images{
        position:absolute;
        top:50%;
        left:50%;

        height:calc(100vh - 192px);
        max-height:600px;
        transform:translate(-50%, -50%);
        width:calc(100% - 192px);
        //max-width:100;
        z-index:3;
        //filter: drop-shadow(0 50px 20px rgba($color-black,0.2));
        @media only screen and (max-width:$br-tablet-min){
            width:calc(100% - 48px);
            height:calc(100% - 96px);
        }
        .work-item-images__container{
            position:absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
            height:100%;
            width:100%;
            //border-radius:4px;
            overflow:hidden;
            
        }
        .work-item-image{
            position:absolute;
            top:0;
            bottom:0;
            width:100%;
            left:0;
            &:after{
                content:"";
                position:absolute;
                top:0;
                bottom:0;
                width:100%;
                left:0;
                background:rgba($color-black0,0.3);
                //mix-blend-mode:multiply;
            }
            &.work-item-image__1{
                clip-path:polygon(0% 100%, 33.33% 100%, 33.33% 100%, 0% 100%);
            }
            &.work-item-image__2{
                clip-path:polygon(33.33% 100%, 66.66% 100%, 66.66% 100%, 33.33% 100%);
            }
            &.work-item-image__3{
                clip-path:polygon(66.66% 100%, 100% 100%, 100% 100%, 66.66% 100%);
            }
        }
        img{
            position:absolute;
            top:0;
            left:0;
            height:calc(125% + 1px);
            width:100%;
            max-width:100%;
            object-fit:cover;
            transform:translate(0, -20%);
            
            
        }
    }
    .work-item-text{
        position:relative;
        z-index:4;
        
        margin-top:0px;
        width:100%;
        display:none;
    }
    .work-item-title{
        position:absolute;
        z-index:4;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
        margin-top:0px;
        width:100%;
        z-index:4;
        
       // transform:translate(-50%,-50%);
        text-align:left;
        .container{
            @media only screen and (max-width:$br-tablet-min){
                padding:0 24px;
            }
        }
        h3{
            width:100%;
            margin-bottom:0;
            //text-shadow:0px 0px 12px rgba($color-black0, 0.5);
            //letter-spacing: 0;
            .o{
                height:1em;
                position:relative;
                width:100%;
                display:block;
            }
            .i{
                position:relative;
                display:block;
                color:transparent;
                -webkit-text-fill-color: transparent;
                -webkit-text-stroke: 1px;
                -webkit-text-stroke-color:rgba($color-gold,1);
                //color:rgba($color-white-base,1);
          
            }
            .i2{
                position:absolute;
                left:0;
                top:0;
                color:rgba($color-gold,1);
                display:block;
                clip-path:inset(0px 0% 0px 0px);
                /*-webkit-text-stroke: 1px;
                -webkit-text-stroke-color:rgba($color-gold,1);*/
            }
        }
    }
}