html{
    height: -webkit-fill-available;
    //cursor:none;
}
body {
    background: $color-black-base;
    height:100vh;
    width:100vw;
    overflow:hidden;
    height: -webkit-fill-available;
    
}

::selection {
    color: $color-black-base;
    background-color: $color-white-base;
}



ol,
ul {
    margin: 0 0 0;
    padding: 0 0 0 0;
    list-style-type: none;
}

li {
    margin: 0;
    padding: 0;
}

em {
    font-style: italic;
}

b,
strong {
    font-weight: bold;
}


img {
    max-width: 100%;
    height: auto;
}

hr {
    margin: 1rem 0;
    border: 0;
    height: 1px;
    background-color: $color-black-base;
}

blockquote {
    margin: 0 0 1rem;
    border-left: 2px solid $color-black-base;
    padding-left: 1rem;
    font-style: italic;

    &.wp-block-quote p {
        margin: 0;
    }

    &.wp-block-quote p + cite {
        margin-top: .5rem;
    }

    &.wp-block-quote cite {
        font-style: normal;
        display: block;
    }
}



.image{
    width:100%;
    height:100%;
    position:relative;
    top:0;
    left:0;
    //border-radius:24px;
    will-change:transform;
    overflow:hidden;
    &.-darken{
        //mix-blend-mode:multiply;
        //background:rgba($color-primary-base, 0.2);
        .image-inner{
            &:before{
                content:"";
                top:0;
                left:0;
                right:0;
                bottom:0;
                position:absolute;
                z-index:3;
                background:rgba($color-black-base, 0.3);
                mix-blend-mode: multiply;

            }
            &:after{
                content:"";
                top:0;
                left:0;
                right:0;
                bottom:0;
                position:absolute;
                z-index:3;
                background:rgba($color-primary-base, 0.3);
                mix-blend-mode: multiply;
    
            }
        }
        
        img{
            

        }
    }
    .image-inner{
        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        overflow:hidden;
        will-change:transform;
        img{
            
            position:absolute;
            top:-25%;
            left:0;
            right:0;
            display:block;
            width:100%;
            height:125%;
            object-fit:cover;
            

        }
    }
}





main{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom:0;
    overflow: hidden;
    z-index:3;
    height: -webkit-fill-available;
    @media only screen and (max-width:$br-tablet-min){
       // overflow:scroll;
    }

    

    
}

.smooth-scroll{
    min-height:100vh;
    min-height: -webkit-fill-available;;
    overflow:hidden;
   
}

section{
    position:relative;
    padding:calc(30px + 3vw) 0 calc(30px + 3vw) 0;
    width:100%;
    
}












.button{
    position:relative;
    height:40px;
    padding:0 24px;
    display:inline-block;
    font-family: $font-head;
    font-weight:500;
    letter-spacing:0.05em;
    color:rgba($color-gold, 1);
    @media only screen and (max-width:$br-tablet-min){
        height:36px;
        padding:0 20px;
    }
    &:hover{
        .sides{
            width:calc(100% + 32px);
            height:calc(100% + 32px);
            opacity:1;
        }
        .mask{
            clip-path:polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
        }
    }
    //color:rgba($color-white-base,1);
    .text{
        height:100%;
        display:flex;
        align-items: center;
        position:relative;
        z-index:3;
        transform:translate(0, 1px);
        @media only screen and (max-width:$br-tablet-min){
            font-size:0.8em;
        }

    }
    .mask{
        position:absolute;
        z-index:0;
        clip-path:polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%);
        //clip-path:polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
        top:0;
        left:0;
        right:0;
        bottom:0;
        transition:all 0.2s 0s $easeInOutExpo;
        background:rgba($color-white-soft,1);
        //background:$gradient1;
        .text{
            color:$color-black-base;
            text-align:center;
            justify-content: center;
        }
    }
    .sides{
        position:absolute;
        top:50%;
        left:50%;
        width:100%;
        height:100%;
        z-index:1;
        transform:translate(-50%,-50%);
        transition:all 0.2s 0s $easeInOutExpo;
        .side{
            position:absolute;
            background:rgba($color-font-base,1);
            //background:rgba($color-white-base,1);
            &.l-t-vertical{
                height:12px;
                width:1px;
                left:0;
                top:0;
            }
            &.l-b-vertical{
                height:12px;
                width:1px;
                left:0;
                bottom:0;
            }
            &.l-t-horizontal{
                height:1px;
                width:12px;
                left:0;
                top:0;
            }
            &.l-b-horizontal{
                height:1px;
                width:12px;
                left:0;
                bottom:0;
            }
            &.r-t-vertical{
                height:12px;
                width:1px;
                right:0;
                top:0;
            }
            &.r-b-vertical{
                height:12px;
                width:1px;
                right:0;
                bottom:0;
            }
            &.r-t-horizontal{
                height:1px;
                width:12px;
                right:0;
                top:0;
            }
            &.r-b-horizontal{
                height:1px;
                width:12px;
                right:0;
                bottom:0;
            }
        }
    }
}



.background-text{
    position:relative;
    top:0%;
    width:100%;
    left:0;
    transform:translate(0,0%);
    z-index:-1;
}
.text-box, .text-box-2{
    width:100%;
    margin-bottom:32px;
    h2{
        .l{
            overflow:hidden;
        }
        .c{
            opacity:1;
            transform:translate(0,0%) scale(1);
        }
    }
    p{
        
        .l{
            overflow:hidden;
        }
        .w{
            opacity:1;
            transform:translate(0,0%);
        }
    }
}
.text-box{
    position:relative;
    z-index:2;
    display:flex;
    flex-flow:row wrap;
    @media only screen and (max-width:$br-tablet-min){
        flex-flow:row wrap;
    }
    .title{
        flex:0 0 100%;
        @media only screen and (max-width:$br-tablet-min){
            flex:0 0 100%;
        }  
    }
    .text{
        flex:0 0 100%;
        position:relative;
        @media only screen and (max-width:$br-tablet-min){
            flex:0 0 100%;
        }
        p{
            margin-bottom:0;
            color:$color-font-light;
            &.-layer1{
               
                opacity:0.2;
            }
            &.-layer2{
                
                position:absolute;
                top:0;
                pointer-events:none;
                .l{
                    clip-path:inset(0px 100% 0px 0px);
                }
            }
            .l{
                display:inline-block !important;
            }
        }
    }
}




.page-loader{
    position:fixed;
    top:0;
    left:0;
    z-index:99;
    width:100%;
    height:100%;
    //backdrop-filter: blur(30px);
    display:none;
    overflow:hidden;
    .shape-1{
        position:absolute;
        top:-50%;
        left:0;
        z-index:3;
        background:rgba($color-gold, 1);
        width:100%;
        height:100%;
        opacity:1;
        //backdrop-filter: blur(3px);
    }
}



#figure{
    position:fixed;
    z-index:3;
    top:0;
    left:0;
    width:100%;
    height:100%;
    .figure-position{
        position:absolute;
        bottom:55%;
        left:50%;
        transform:translate(-50%,0%);
        width:70vw;
        height:0;
        max-width:70vh;
        @media only screen and (max-width:$br-tablet-min){
            width:90vw;
        }
        
        
    }
    .figure-proportion{
        position:absolute;
        width:100%;
        padding-top:100%;
        transform:translate(-50%,-50%);
        mask-image: linear-gradient(rgba(0,0,0,1) 0%, rgba(0,0,0,0) 90%);
        left:50%;
    }
    .figure-picture{
        position:absolute;
        top:0%;
        left:0%;
        transform:translate(0%,0%);
        z-index:-3;
        background-image:url(../images/stefan9b.png);
        //background:blue;
        filter:grayscale(100%) contrast(1.2) brightness(1);
        background-size:contain;
        background-position:center center;
        background-repeat:no-repeat;
        width:100%;
        height:100%;
        //padding-top:100%;
        opacity:0.5;
        mix-blend-mode:darken;
        //filter:blur(3px) grayscale(100%);
    }
}


.footer{
    padding:6px 0;
    font-weight:400;
    font-size:0.85em;
}