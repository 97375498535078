.-mobile-nav-open{
    .mobile-nav-overlay{
       //@media only screen and (max-width:$br-tablet-min){
            z-index:9;
            transition:all 0s 0s ease;
            pointer-events:all;
        //}
    }
}
.mobile-nav-overlay{
    position:fixed;
    z-index:-2;
    top:0;
    left:0;
    right:0;
    bottom:0;
    display:flex;
    flex-flow:row nowrap;
    //opacity:0;
    transform:translateZ(0px);
    will-change: transform;
    pointer-events:none;
    transition:all 0s 1.5s ease;
    .inner{
        width:100%;
        height:100%;
        position:absolute;
        transform:translate(0, 0%) scale(1);
        left:0%;
        opacity:0;
        //margin-left:1px;
        background:rgba(2, 4, 7, 0.8);
        backdrop-filter:blur(8px);
    }
}
.background-noise{
    position:fixed;
    z-index:-3;
    top:0;
    left:0;
    right:0;
    bottom:0;
    overflow:hidden;
    opacity:0.5;
    //display:none;
    .inner{
        width:100%;
        height:100%;
        position:absolute;
        transform:translate(0, 0%) scale(1);
        left:0%;
        top:0;
        background-image:url(../images/noise.png);
        background-size:160px;
    }
}