.section-intro{
    height:100%;
    min-height: auto;
    padding-top:0;
    padding-bottom:0;


    .intro-text{
        position:absolute;
        bottom:0vh;
        left:50%;
        transform:translate(-50%,0%);
        text-align:center;
        width:100%;
        display:flex;
        justify-content: center;
        align-items: flex-start;
        z-index:3;
        min-height:40vh;
        &>.container{
            max-width:100%;
            padding:0 24px;
        }
      
        h5{
            margin:0 auto 0.3em;
            //text-transform:none;
            
            color:rgba($color-white-base,1);
            
            &>div{
                &>div{
                    transform:translate(0px,100%);
                    //opacity:0;
                }
            }
        }
        h1{
            margin-bottom:0.2em;
            position:relative;
            display:block;
            justify-content: center;
            gap:0 0px;
            flex-flow:row wrap;
            //text-shadow:0px 0px 8px rgba($color-black2,0.4);
            //text-transform:none;
            //font-family:$font-body;
            
            &>div{
                
                &>div{
                    transform:translate(0,100%);
                    //opacity:0;
                }
            }
        }
        p{
            max-width:600px;
            width:100%;
            margin:24px auto 0;
            //display:none;
            padding-left:24px;
            padding-right:24px;
    
            &>div{
                
                &>div{
                    transform:translate(0,100%);
                    //opacity:1;
                }
            }
        }
        h5,h1,p{
            &>div{
                position:relative;
                overflow:hidden;
                
            }
        }
        .button-container{
            display:none;
        }
        .button{
            pointer-events:all;
        }
    }





}

.intro-images{
    width:100%;
    max-width:260px;
    margin:calc(3vw + 30px) auto 0;
    position:relative;
    top:0%;
    left:0%;
    transform:translate(0%,0%);
    @media only screen and (max-width:$br-tablet-min){
        max-width:200px;
        margin-top:32px;
        margin-bottom:32px;
    }
    .image-box{
        width:100%;
        height:0;
        padding-top:160%;
        position:relative;
        perspective:800px;
        .info-box{
            position:absolute;
            background:rgba($color-white-base,1);
            min-height:36px;
            border-radius:18px;
            padding:0 12px;
            display:flex;
            align-items: center;
            font-family:$font-head;
            display:none;
            &#info-box-1{
                top:20%;
                left:-10%;
            }
            &#info-box-2{
                top:95%;
                left:30%;
            }
            &#info-box-3{
                top:50%;
                right:-20%;
            }
        }
        .border-lines{
            position:absolute;
            top:15%;
            bottom:0;
            left:0;
            width:100%;
            opacity:0.1;
            .border{

                position:absolute;
                top:50%;
                left:50%;
                width:100%;
                height:100%;
                border:solid 1px $color-white-soft2;
                //border-radius:0 !important;
                transform:translate(-50%,-50%);
            }
        }
        .image{
            position:absolute;
            top:0;
            border-radius:130px;
            //transform:translateZ(0);
            //transform:rotateY(180deg);
            &.image-1{
                //display:none;
                
                clip-path:polygon(0% 0, 100% 0, 100% 100%, 0 100%);
                &:after{
                    content:"";
                    border-radius: 130px;
                    position:absolute;
                    top:15%;
                    left:0;
                    z-index:-1;
                    width:100%;
                    bottom:0;
                    opacity:1;
                    background:linear-gradient(-45deg, rgba($gradient1-2,1) 0%, rgba($gradient1-1,1));
                    background:rgba($color-white-soft,1);
                   
                }
                img{
                    display:none;
                    //opacity:0;
                    opacity:0.4;
                    
                    //filter: grayscale(100%) contrast(1);
                    
                }
            }
            &.image-2{
                //transform:translateZ(130px);
                &:after{
                    //content:"";
                    border-radius: 0px;
                    position:absolute;
                    top:0%;
                    border-radius:130px;
                    width:180%;
                    max-width:200%;
                    margin:-35% auto 0 -40%;
                    height:160%;
                    opacity:0.1;
                    z-index:1;
                    //mix-blend-mode:multiply;
                    //background:linear-gradient(45deg, rgba($gradient1-2,1) 0%, rgba($gradient1-1,1));
                    mask-image: url(/assets/images/stefan6.png);
                    mask-size:cover;
                    mask-repeat: no-repeat;
                    mask-position: center;
                    //background:rgba($color-white-soft2,1);
                }
                img{
                    opacity:1;
                    filter:grayscale(100%);
                    //mix-blend-mode:multiply;
                }
            }
            img{
                position:relative;
                width:180%;
                max-width:200%;
                margin:-35% auto 0 -40%;
                height:160%;
                object-fit:cover;
                
                
                
                
                
            }
        }
    }
}
