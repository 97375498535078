@mixin font-responsive($font-min, $font-max, $screen-min, $screen-max) {

	font-size: #{$font-min}px;

    @media only screen and (min-width: #{$screen-min}px) {
		font-size: calc(
			#{$font-min}px + #{($font-max - $font-min)} * (100vw - #{$screen-min}px) / (#{$screen-max} - #{$screen-min})
		);
    }

    @media only screen and (min-width: #{$screen-max}px) {
    	font-size: #{$font-max}px;
    }

}


@mixin r-font($responsive, $min, $max: false, $fallback: false) {
    $responsive-unitless: math.div($responsive, $responsive - $responsive + 1);
    $dimension: if(unit($responsive) == 'vh', 'height', 'width');
    $min-breakpoint: math.div($min, $responsive-unitless) * 100;

    @media (max-#{$dimension}: #{$min-breakpoint}) {
        font-size: $min;
    }

    @if $max {
        $max-breakpoint: math.div($max, $responsive-unitless) * 100;

        @media (min-#{$dimension}: #{$max-breakpoint}) {
            font-size: $max;
        }
    }

    @if $fallback {
        font-size: $fallback;
    }

    font-size: $responsive;
}




/* SASS mixin start */

/// $min - Minimum font size (px, rem)
/// $max - Maximum font size (px, rem)
/// $min-vw - Minimum viewport value lock range (px) --(optional)
/// $max-vw - Maximum viewport value lock range (px) --(optional)
/// $fallback - fallback font size (px, rem) --(optional)

@mixin fluid-font($min, $max, $min-vw: 400px, $max-vw: 1600px, $fallback: false) {
    $min-no-unit : strip-units($min);
    $max-no-unit : strip-units($max);
    
    $min-vw-no-unit : strip-units($min-vw);
    $max-vw-no-unit : strip-units($max-vw);
    
    @if getUnit($min) == "rem" or getUnit($min) == "em" {
      $min-no-unit : strip-units($min) * 16;
      $max-no-unit : strip-units($max) * 16;
    }
    
     $responsive : calc(#{$min-no-unit}px + (#{$max-no-unit} - #{$min-no-unit}) * ((100vw - #{$min-vw}) / (#{$max-vw-no-unit} - #{$min-vw-no-unit})));
    
    @if $fallback { font-size: $fallback; }
    @else { font-size: $max; }
  
    font-size:  $responsive;
  
     @media screen and (max-width: #{$min-vw}) { font-size: $min; }
     @media screen and (min-width: #{$max-vw}) { font-size: $max; }
  }
  
  @function strip-units($number) {
    @return math.div($number, $number * 0 + 1);
    
  }
  
  @function getUnit($value) {
    @return str-slice($value * 0 + "", 2, -1);
  }