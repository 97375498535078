#penrose{
    position:absolute;
  color: #fff;
  width:10000px;
  width:96px;
  max-width:60vh;
  
  display: flex;
  justify-content: center;
  align-items: center;
  height:0vh;
  left:50%;
  z-index:-2;
  top:50%;
  transform:translate(-50%, 0) scale(1);
  opacity:1;
  //filter:blur(4px);
  //filter:drop-shadow(2px 22px 6px black);
  @media only screen and (max-width:$br-mobile-3){

    //max-width:60vw;
  }

}
.penrose-background{
    position:absolute;
    top:-40vh;
    left:50%;
    //width:calc(100vw - 128px);
    //height:calc(100vh - 128px);
    width:100vw;
    height:90vh;
    z-index:1;
    //display:none;
    overflow:hidden;
    transform:translate(-50%,0%);
    opacity:1;
    //background:rgba($color-black0,0.7);
    
    &:after{
        content:"";
        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        z-index:4;
        
        

    }
    .penrose-background-img{
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
        z-index:-3;
        background-image:url(../images/stefan9b.png);
        //background:blue;
        filter:grayscale(100%) contrast(1) brightness(1);
        background-size:contain;
        background-position:center center;
        background-repeat:no-repeat;
        width:100%;
        height:100%;
        opacity:0.2;
        mix-blend-mode:darken;
        //filter:blur(3px) grayscale(100%);
    }
}

.penrose-triangles{
    position:absolute;
    top:50%;
    left:50%;
    width:100%;
    height:0%;
    padding-top:86%;
    z-index:2;
    transform:translate(-50%,-50%);
    perspective:300px;
    perspective-origin: center 50%;
    display:none;
    .triangles{
        width: 100%;
        height: 100%;
        position: absolute;
        transform-style: preserve-3d;
        top:0%;
        left:0%;
    }
    .triangle{
        position:absolute;
        
        top:0%;
        left:0%;
        width:100%;
        height:100%;
        //display:none;
        opacity:1;
        perspective:900px;


        svg{
            
            width:100%;
            height:100%;
    
            
            polygon{
                fill:none;
                stroke:rgba($color-black4,1);
                opacity:1;
                stroke-width:0.4px;
               

            }
        }
    }
}

.penrose-2{
    position: relative;
    //filter: drop-shadow(0 50px 20px rgba($color-black,0.5));
    width:100%;
    height:0;
    padding-top:100%;
    z-index:3;
    //display:none;
    transform:translate(0%,0%);
    opacity:1;
    .triangle-side{
        width:100%;
        height:100%;
        //background:blue;
        position:absolute;
        top:0;
        left:0;
        
        
        mask-size:contain;
        mask-repeat:no-repeat;
        mask-position:center center;
        //background:rgba($color-black2,1);
        overflow:hidden;
        opacity:0;
        &.triangle-side-1{
            //display:none;
            mask-image: url(../images/hexagon/side-1.svg);
            //background:blue;
            .after{
                //background-image: linear-gradient(30deg, rgba($color-black,0.3) 40%, rgba($color-black, 0.8) 50%);
                background-image:linear-gradient(90deg, rgba($color-black, 0.95) 40%, rgba($color-black, 0.1) 70%);
                //background-image: linear-gradient(0deg, rgba($color-black,0.9) 40%, rgba($color-black,1) 50%);
            }
            .triangle-inside{
                //transform:rotate(30deg);
            }
        }
        &.triangle-side-2{
            //display:none;
            mask-image: url(../images/hexagon/side-2.svg);
            //background:green;
            .after{
               // background-image: linear-gradient(-120deg, rgba($color-black,0.2) 45%, rgba($color-black, 0.8) 70%);
                background-image:linear-gradient(150deg, rgba($color-black, 0.95) 40%, rgba($color-black, 0.1) 70%);
                //background-image: linear-gradient(-120deg, rgba($color-black,0.6) 50%, rgba($color-black,1) 60%);
            }
            .triangle-inside{
                //transform:rotate(-60deg);
            }

        }
        &.triangle-side-3{
            //display:none;
            mask-image: url(../images/hexagon/side-3.svg);
            //background:red;
            .after{
               // background-image: linear-gradient(120deg, rgba($color-black,0.1) 45%, rgba($color-black,0.8) 70%);
                background-image:linear-gradient(210deg, rgba($color-black, 0.95) 40%, rgba($color-black, 0.1) 70%);
                //background-image: linear-gradient(120deg, rgba($color-black0,0.1) 46%, rgba($color-black0,0.9) 54%);
            }
            .triangle-inside{
                //transform:rotate(0deg);
            }
        }
        &.triangle-side-4{
            //display:none;
            mask-image: url(../images/hexagon/side-4.svg);
            //background:red;
            .after{
               // background-image: linear-gradient(120deg, rgba($color-black,0.1) 45%, rgba($color-black,0.8) 70%);
                background-image:linear-gradient(270deg, rgba($color-black, 0.95) 40%, rgba($color-black, 0.1) 70%);
                //background-image: linear-gradient(120deg, rgba($color-black0,0.1) 46%, rgba($color-black0,0.9) 54%);
            }
            .triangle-inside{
               //transform:rotate(45deg);
            }
        }
        &.triangle-side-5{
            //display:none;
            mask-image: url(../images/hexagon/side-5.svg);
            //background:red;
            .after{
                //background-image: linear-gradient(120deg, rgba($color-black,0.1) 45%, rgba($color-black,0.8) 70%);
                background-image:linear-gradient(330deg, rgba($color-black, 0.95) 40%, rgba($color-black, 0.1) 70%);
                //background-image: linear-gradient(120deg, rgba($color-black0,0.1) 46%, rgba($color-black0,0.9) 54%);
            }
            .triangle-inside{
              //transform:rotate(210deg);
            }
        }
        &.triangle-side-6{
            //display:none;
            mask-image: url(../images/hexagon/side-6.svg);
           
            //background:red;
            .after{
                //background-image: linear-gradient(120deg, rgba($color-black,0.1) 45%, rgba($color-black,0.8) 70%);
                background-image:linear-gradient(30deg, rgba($color-black, 0.95) 40%, rgba($color-black, 0.1) 70%);
                //background-image: linear-gradient(120deg, rgba($color-black0,0.1) 46%, rgba($color-black0,0.9) 54%);
                //clip-path:polygon(0% 50%, 100% 50%, 100% 100%, 0% 100%);
                //transform:rotate(30deg);
            }
            .triangle-inside{
               // transform:rotate(330deg);
                //clip-path:polygon(0% 50%, 100% 50%, 100% 100%, 0% 100%);
                transform:rotate(30deg);
            }
        }
        .after{
            content:"";
            position:absolute;
            top:-10%;
            left:-10%;
            width:120%;
            height:120%;
            //display:none;
            z-index:4;
            opacity:0;
            mix-blend-mode:darken;
           
            //background:rgba($color-black2,1);
            //background-image: linear-gradient(to right, rgba($color-black2,0.9) 20%, rgba($color-black2, 0.3) 60%);
        }
        
    }
    .triangle-inside{
        position:absolute;
        width:100%;
        height:100%;
        left:0;
        top:0;
        -webkit-backface-visibility: hidden;
        transform:translateZ(0);
        background:rgba($color-gold,1);
        opacity:1;
    }
    .triangle-img{
        position:absolute;
        top:50%;
        left:50%;
        z-index:2;
        background-image:url(../images/stefan/stefan9.jpg);
        //background-color:blue;
        background-size:cover;
        background-position:center center;
        width:80vw;
        max-width:600px;
        //max-width:1024px;
        height:80vh;
        max-height:600px;
        //max-height:800px;
        transform:translate(-50%,-50%);
        filter:grayscale(1) brightness(0.8) contrast(1.2);
        opacity:0.4; //0.1
        mix-blend-mode: overlay;
        

    }


    svg#triangle-outline-svg{
        position:absolute;
        top:0%;
        left:0;
        width:100%;
        height:100%;
        //display:none;
        path{
            
            opacity:1;
            
                stroke:rgba($color-gold,1);
            
        }
    }
}