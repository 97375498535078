.container{
    padding:0 96px;
    width:100%;
    max-width:1140px;
    margin:0 auto;
    @media (min-width:$br-desktop-max){
        padding:0 24px;
    }
    @media(max-width:$br-tablet-min){
        padding:0 24px;
    }
}
.container-big{
    padding:0 3.125vw;
    max-width:2560px;
    @media(max-width:$br-tablet-min){
        padding:0 24px;
    }
}
.container-full{
    padding:0 0vw;
    max-width:100%;
    @media(max-width:$br-tablet-min){
        padding:0 0;
    }
}
.container-small{
    padding:0 3.125vw;
    max-width:767px;
    @media(max-width:$br-tablet-min){
        padding:0 24px;
    }
}
.container-text{
    max-width:960px;
}
.container-gallery{
    padding:0 3.125vw;
    max-width:1440px;
    @media(max-width:$br-tablet-min){
        padding:0 24px;
    }
}
.container-navigation{
    padding-right:0;
    @media (min-width:$br-desktop-max){
        //padding:0 9.375vw;
    }
    @media (max-width:$br-desktop-min){
        padding:0 9.375vw;
    }
    @media(max-width:$br-tablet-min){
        padding:0 24px;
    }
}

.container-list{
    padding:0 3.125vw;
    max-width:1920px;
    @media(max-width:$br-tablet-min){
        padding:0 24px;
    }
}
