.section-contact{
    min-height:80vh;
    .contact-box{
        display:flex;
        flex-flow:row wrap;
        padding-top:10vw;
        padding-bottom:6vw;

    }
    .contact-line{
        width:50%;
        //@include fluid-font(40px,60px, 320px, 1440px);
        line-height:1em;
        margin:12px 0;
        @media only screen and (max-width:$br-tablet-min){
            width:100%;
        }

        

    }
}


.button-clip{
    display:block;
    font-weight:400;
    width:100%;
    position:relative;
    padding:0px 0px;
    &:hover{
        .overlay{
            //clip-path:polygon(0% 0%, 100% 0%, 100% 100%, 0 100%);
        }
    }
    .front{
        position:relative;
        z-index:2;
        
        .t{
            display:block;
            color:$color-font-light;
            font-weight:500;
            font-size:0.9em;
            //text-transform:uppercase;
            //letter-spacing: 0.2em;
            margin-bottom:0.3em;
        }
        .a{
            display:block;
            color:$color-gold;
            //color:$color-font-light;
            svg{
                path{
                    fill:$color-gold;
                }
            }
        }
    }
    .overlay{
        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        display:flex;
        align-items: center;
        clip-path:polygon(0% 50%, 100% 50%, 100% 50%, 0 50%);
        transition:clip-path 0.2s 0s ease;
        z-index:4;
        background:$color-gold;
        padding:0px 6px;
        display:none;
        .t{
            color:$color-white-base;
        }
    }
    
}