body.-mobile-nav-open{
    .top-nav-background{
        //opacity:0;
        transition:opacity 0.4s 0s $easeInOutExpo;
        .nav-border{
            //opacity:0;
            transition:opacity 0.4s 0s $easeInOutExpo;
        }
    }

}




.-mobile-nav-open{
    nav.mobile-nav{
        //@media only screen and (max-width:$br-tablet-min){
            z-index:9;
            opacity:1;
            transition:all 0s 0s ease;
       // }
    }
}

nav.mobile-nav{
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:-3;
    opacity:0;
    transition:all 0s 1s ease;
    .container{
        height:100%;
        display:flex;
        align-items:center;
        position:relative;
        z-index:4;
        justify-content: space-between;

    }

    ul{
        display:flex;
        flex-flow:column nowrap;
        justify-content:center;
        height:100%;
        width:100%;
        align-items:center;
        li{
            height:$navHeight;
            opacity:0;
            transform:translate(0,30px);
            a{
                text-transform:uppercase;
                font-size:1.3em;
                //margin-right:12px;
                font-family:$font-head;
                letter-spacing:0.1em;
                transition:all 0.2s 0s $easeInOutExpo;
                padding:0 32px;
                font-weight:400;
                height:$navHeight;
                display:flex;
                align-items:center;
                color:rgba($color-gold,0.8);
                &:hover{
                    color:rgba($color-gold,1);
                }
            }
        }
    }
    

}

#top-nav{
    position:fixed;
    will-change: transform;
    transform:translateZ(0px);
    height:64px;
    width:100%;
    left:0;
    top:0px;
    z-index:11;
    #top-nav__bg{
        z-index:1;
        top:-64px;
        left:0;
        transform:translate(0,0px);
        width:100%;
        height:64px;
        border-bottom:solid 1px rgba($color-black2-1,1);
        position:absolute;
        overflow:hidden;
        background:rgba($color-black0,0.6);
        backdrop-filter:blur(20px);
    }
}

.element-bg__parent-hover{
    &:hover{
        .element-bg{
            background:rgba($color-black0, 0.8);
            //border-color:rgba($color-gold,0.8);
            //box-shadow:0px 6px 12px -8px rgba($color-gold,0.4);
        }
    }
}
.element-bg{
    position:absolute;
    top:0;
    left:0; 
    //background:rgba(3,3,3,0.7);
    background:rgba($color-black0, 0.6);
    //border-bottom:solid 1px rgba($color-black2-1,1);
    transition:all 0.3s 0s $easeInOutExpo;
    //border:solid 1px rgba($color-gold,0.2);
    box-shadow:0px 0px 12px -8px rgba($color-gold,0);
    z-index:1;
    height:100%;
    width:100%;
    transform:scale(1);
    //border-radius:3px;
    backdrop-filter:blur(5px);
}

#site-logo{
    padding:0;
    height:$navHeight;
    width:$navHeight;
    cursor:pointer;
    display:flex;
    align-items:center;
    justify-content: center;;
    flex-flow:row nowrap;
    position:fixed;
    top:24px;
    left:24px;
    z-index:12;
    //display:none;
    color:rgba($color-gold,1);
    
    @media only screen and (max-width:$br-tablet-min){
        top:24px;
        left:24px;
        //height:$navHeightMobile;
        //width:$navHeightMobile;
    }
    
    .txt{
        text-transform:uppercase;
        font-family:$font-head;
        position:relative;
        z-index:3;
        transform:translate(0, 6%);
        letter-spacing:0.05em;
        font-size:0.9em;
        font-weight:400;
        transition:all 0.2s 0s $easeInOutExpo;
    }
}


body:not(.-mobile-nav-open){
    .hamburger-icon{
        &:hover{
            .text{
                color:rgba($color-white-soft,1);
            }
            /*.line{
                &#line-1{
                    .i{
                        width:14px;
                        left:-2px;
                    }
                }
                &#line-2{
                    .i{
                        width:20px;
                        left:-8px;
                    }
                }
                &#line-3{
                    .i{
                        width:8px;
                        left:4px;
                    }
                }
    
                .i{
                    background:rgba($color-gold,1);
                }
            }*/
            
            
        }
    }
}


.hamburger-icon{
    padding-right:0px;
    height:$navHeight;
    width:$navHeight;
    cursor:pointer;
    display:flex;
    align-items:center;
    flex-flow:row nowrap;
    position:fixed;
    top:24px;
    right:24px;
    z-index:12;
    color:$color-gold;
    .lines{
        width:100%;
        height:100%;
        z-index:4;
        position:relative;
        //background:blue;
    }
    .line{
        position:absolute;
        width:0px;
        height:0px;
        left:50%;
        top:50%;
        &#line-1{
        
        }
        &#line-2{
            .i{
                width:12px;
                left:4px;
               
            }
        }
        &#line-3{

        }
        .i{

            position:absolute;
            width:20px;
            height:1px;
            top:0;
            left:0;
            opacity:0;
            transform:translate(-50%,-50%);
            background:rgba($color-gold,1);
  
        }
    }
}

nav.side-nav{
    position:fixed;
    top:0;
    left:0;
    bottom:0;
    z-index:99;
    display:none;
    @media(max-width:$br-tablet-min){
        display:none;
    }
    ul{
        top:50%;
        transform:translate(0, -50%);
        position:absolute;
        display:flex;
        flex-flow:column nowrap;
        padding:0px 0 0px 12px;
        li{
            
            position:relative;
            font-family:$font-head;
            &.-active{
                .line{
                    background:rgba($color-white-base,1);
                    width:24px;
                }
                .title{
                    color:rgba($color-font-white,1);
                    opacity:0;
                    transform:translate(8px,0);
                }
            }
            &:hover{
                .line{
                    //background:rgba($color-white-base,1);
                    width:24px;
                }
                .title{
                    
                    opacity:1;
                    transform:translate(8px,0);
                }
            }
            a{
                height:16px;
                display:flex;
                position:relative;
                font-family:$font-head;
                color:$color-white-base;
                font-weight:300;
                text-transform:uppercase;
                align-items:center;
            }
            .line{
                position:absolute;
                top:50%;
                width:16px;
                height:1px;
                margin-top:-1px;
                background:rgba($color-black4,1);
                transition:all 0.4s 0s $easeInOutExpo;
            }
            .title{
                margin-left:30px;
                display:block;
                color:rgba($color-font-dark,1);
                transform:translate(0px, 0);
                line-height:1em;
                opacity:0;
                font-size:0.9em;
                transition:all 0.4s 0s $easeInOutExpo;
                //display:none;
            }
            
        }
    }
    
}