body{
    font-weight: normal;
    line-height: 1.61em;
    color: $color-font-base;
    font-family:$font-body;
    font-weight:400;
    @include fluid-font(16px, 19px,320px, 1440px);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5{
    margin: 0 0 0.65em;
    line-height: 1.2em;
    color: $color-gold;
    font-weight: 500;
    ///font-family:$font-head;
    //font-family:$font-head2;
    text-transform:uppercase;
    letter-spacing:0.02em;
    &.-regular{
        font-weight:400;
    }
}



h1, .h1{
    
    @include fluid-font(42px,80px, 320px, 1440px);
    //text-transform:none;
    //color:transparent;
    //-webkit-text-stroke: rgba($color-font-base,1) 1px;
    letter-spacing:0.2em;
    line-height:1.1em;
    font-weight:700;
    &.-big{
        @include r-font( 8.333vw, 64px, 160px, 160px );
    
    }
}
h2, .h2{
    @include fluid-font(24px, 48px, 320px, 1440px);
    line-height:1.1em;
    letter-spacing:0.2em;
    &.-small{
        @include fluid-font(15px, 17px, 320px, 1440px);
        letter-spacing:0.33em;
        color:$color-font-base;
        //font-family:$font-body;
        //letter-spacing:0em;
        //@include r-font( 8vw, 120px, 240px, 240px );
        //@include fluid-font(80px, 240px);
    }
}
h3, .h3{
    //@include fluid-font(18px, 36px);
    @include fluid-font(36px, 52px, 320px, 1440px);
    line-height:1.1em;
    letter-spacing:0.1em;
    font-weight:600;
    
}
h4, .h4{
    @include r-font( 1.25vw, 20px, 24px, 24px );
}
h5, .h5{
    @include fluid-font(14px, 18px,320px, 1440px);
    letter-spacing:1em;
}

.-super{
    margin-bottom:0.1em;
    font-family:$font-body;
}


p{


    margin: 0 0 2em;
    //color:$color-font-light;
    color:$color-font-base;
    font-family:$font-body;
    &:empty{
        display:none;
    }

    &.-big{
        
        @include fluid-font(32px, 48px,320px, 1440px);
        font-weight:700;
        line-height:1.25em;
        
    }
    a {
        text-decoration: none;
        color:$color-gold;
        //font-weight:500;
        &:hover {
            text-decoration:none;
        }
    }
    .-highlight{
        color:$color-secondary-base;
    }
}
a {
    text-decoration: none;
    cursor:pointer;

    color:$color-primary-base;
    &:hover {
        text-decoration: none;
    }
}



.-color-primary-base, .-color-primary{
    color:$color-primary-base;
}
.-color-font-base{
    color:$color-font-base;
}
.-color-font-light{
    color:$color-font-light;
}
.-color-white{
    color:$color-white-base;
}
.-bold{
    font-weight:700;
}





.text-header{

    width:100%;
    max-width:767px;
    margin:0 auto calc(20px + 5vw);
    text-align:center;
    h2{
        margin-bottom:8px;
    }
    p{
        @include r-font( 1.25vw, 14px, 24px, 24px );
    }
    .button__container{
        flex:0 1 300px;
        display:flex;
        justify-content: flex-end;
        align-items: flex-end;
    }
}
