.scroll-indicator-mouse{
    position:absolute;
    left:50%;
    bottom:12px;
    transform:translate(-50%, 20px);
    display:flex;
    flex-flow:column nowrap;
    align-items:center;
    width:20px;
    height:32px;
    opacity:0;

    .mouse-shape{
        width:20px;
        height:32px;
        border-radius:10px;
        border:solid 1px rgba($color-font-light,1);
        position:absolute;
        left:50%;
        transform:translate(-50%, 0);
        bottom:0;
    }
    .mouse-scroll{
        position:absolute;
        left:50%;
        transform:translate(-50%, 0);
        width:1px;
        height:6px;
        background:rgba($color-font-light,1);
        top:8px;

    }
}

.scroll-indicator{
    position:absolute;
    left:50%;
    bottom:6px;
    transform:translate(-50%, 0);
    display:flex;
    flex-flow:column nowrap;
    align-items:center;
    display:none;
    @media only screen and (max-width:$br-tablet-min){
        //top:calc(100vh - 160px);
    }
    .scroll-indicator__text{
        width:100%;
        position:relative;
        text-align:center;
        text-transform:uppercase;
        font-weight:300;
        font-size:0.85em;
        //color:rgba($color-font-gray,1);
        font-family:$font-head;
        margin-bottom:6px;
        line-height:1em;
        letter-spacing: 0.2em;
       //display:none;
    }
    .scroll-indicator__shapes{
        width:24px;
        position:relative;
        display:flex;
        flex-flow:column-reverse nowrap;

        .shape{
            position:relative;
            width:24px;
            height:12px;
            margin-top:-2px;
            &#shape-1{
                opacity:0.7;
            }
            &#shape-2{
                opacity:0.5;
            }
            &#shape-3{
                opacity:0.3;
            }
            &#shape-4{
                opacity:0.1;
            }
            &#shape-5{
                opacity:0.0;
            }
            &#shape-6{
                opacity:0.0;
            }
            .l{
                position:absolute;
                bottom:0;
                width:12px;
                height:1px;
                background:rgba($color-font-light,1);
                
                &.l-l{
                    transform:rotate(36deg);
                    transform-origin:right bottom;
                    right:50%;
                }
                &.l-r{
                    transform:rotate(-36deg);
                    transform-origin:left bottom;
                    left:50%;
                }
            }
        }
    }
    
}


html.-custom-cursor{
    //cursor:none !important;
    *{
       // cursor:none !important;
    }
    .cursor-overlay{
        opacity:1;
        @media only screen and (max-width:$br-tablet-min){
            opacity:0;
        }
    }
    
}


.cursor-overlay{
    pointer-events:none;
    //mix-blend-mode: difference;
    position: absolute;
    //background:white;
    width:100%;
    height:100%;
    opacity:1;
    z-index:11;

    mix-blend-mode: difference;
    opacity:0;
    transition:all 0s 0.8s ease;

    .cursor{
        top:0%;
        left:0%;
        width:0px;
        height:0px;
        position:absolute;
        //background:rgba(0,24,124,0.5);
       // pointer-events:none;
       
        //backdrop-filter:invert(1);
        //backdrop-filter: blur(3px);
        //mix-blend-mode: difference;
        
    }
    .cursor-shape-1{
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%) rotate(45deg);
        width:4px;
        height:4px;
        background: rgba($color-gold,1);
        
        border-radius:50%;
        opacity:1;
        transition:all 0.4s 0s ease;
        &.-big{
            opacity:1;
            width:60px;
            height:60px;
        }
    }
    .cursor-shape-2{
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%) rotate(45deg);
        width:20px;
        height:20px;
        border:solid 1px rgba($color-gold,1);
        
        border-radius:50%;
        opacity:1;
        transition:all 0.4s 0s ease;
        &.-big{
            opacity:1;
            width:60px;
            height:60px;
        }
    }
    .cursor-shape-arrow{
        width:0px;
        height:0px;
        position:absolute;
        //background:blue;
        opacity:0;
        left:50%;
        
        &.-up{
            transform:translate(-50%, -11.5px) rotate(180deg);
        }
        &.-down{
          
            transform:translate(-50%, 11.5px) rotate(0deg);
            .l{

            }
        }
        .r{
            position:absolute;
            width:100%;
            height:100%;
        }
        .l{
            width:16px;
            height:1px;
            background:rgba($color-gold,1);
            position:absolute;
            bottom:0;
            
            &.l1{
                right:50%;
                transform:rotate(45deg);
                transform-origin:right center;
            }
            &.l2{
                left:50%;
                transform-origin:left center;
                transform:rotate(-45deg);
            }
        }
    }
    .cursor-shape-2{
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
        width:30px;
        height:30px;
        border:solid 1px rgba($color-gold,1);
        border-radius:50%;
        transition:all 0.4s 0s ease;
    }
    .cursor-shape-3{
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
        width:24px;
        height:24px;
        border:solid 1px rgba($color-gold,1);
        border-radius:50%;
        transition:all 0.4s 0s ease;
        .t{
            position:absolute;
            opacity:1;
            top:50%;
            left:50%;
            transform:translate(-50%,-50%);
            white-space:wrap;
            line-height:1.0em;
            color:rgba($color-white-base,1);
            width:92px;
            height:92px;
            display:flex;
            text-align:center;
            align-items:center;
            justify-content: center;


        }
    }
    
    #cursor-inner{
        

    }
}

.-cursor-magnet{
   
    .magnet-area{
        background:rgba(255,255,255,0.3);
        content:"";
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
        z-index:5;
        pointer-events:all;
        width:400%;
        height:400%;
    }
}
