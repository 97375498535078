
.hexagon-shape{
    //display:none;
    position:fixed;
    margin:0 auto;
    //background:$color-white-base;
    width:346px;
    height:400px;
    z-index:2;
    top:50%;
    left:50%;
    transform: translate(-50%, -60%) scale(1);
    perspective:900px;
    filter:blur(0px);
    display:none;
  
    .inner{
        //display:none;
        background-size:cover;
        transform-origin:center center;
        position: absolute;
        top: 50%;
        left: 50%;
        width:100%;
        height:100%;
        transform-style: preserve-3d;
        transform: translate(-50%, -50%) scale(1) rotate(0deg);
        display:flex;
        flex-flow:row nowrap;
        justify-content:center;
        align-items:center;
        opacity:0;
        //background:red;
        
    }
    .inner-rotate{
        position: absolute;
        top: 50%;
        left: 50%;
        width:100%;
        height:100%;
        transform: translate(-50%, -50%) scale(0.9);
        //-webkit-mask-image: url(../images/stefan3.png);
        //-webkit-mask-size: contain;
        @media only screen and (max-width:$br-tablet-min){
            transform:translate(-50%, -50%) scale(0.8);
        }
        
    }
    .axe{
        width:200px;
        height:0px;
        //background:blue;
        position:absolute;
        right:50%;
        top:50%;
        transform-origin:right center;
        &#axe-1{
            transform:rotateZ(330deg);
        }
        &#axe-2{
            transform:rotateZ(270deg);
        }
        &#axe-3{
            transform:rotateZ(210deg);
            //transform:translate3d(-50%, 0%,0px) rotate(120deg);
        }
        &#axe-4{
            transform:rotateZ(150deg);
            //transform:translate3d(-50%, 0%,0px) rotate(180deg);
        }
        &#axe-5{
            transform:rotateZ(90deg);
            //transform:translate3d(-50%, 0%,0px) rotate(240deg);
        }
        &#axe-6{
            transform:rotateZ(30deg);
            //transform:translate3d(-50%, 0%,0px) rotate(300deg);
        }



        .axe-inner{
            
            //background:$color-black2-1;
            position:absolute;
            width:92px;
            height:148px;
            top:0;
            left:0;
            transform-origin:left top;
            transform:rotate(-30deg);
            display:flex;
            flex-flow: column-reverse nowrap;
            justify-content:flex-end;
            
            
        }

        .mask{
            //display:none;
            position:relative;
            width:100%;
            //overflow:hidden;
            transform:translate(0%, -133%);
            opacity:0;
            //flex:1 0 auto;
            height:calc(148px / 8);
            
            //filter:grayscale(100%);
            /*&:hover{
                .mask-inner{
                    opacity:1;
                }
                .line{
                    .r, .l{
                        box-shadow:0px 0px 5px 1px white;
                    }
                }
            }*/
            .mask-inner{
                position:absolute;
                top:0px;
                left:0;
                width:100%;
                height:100%;
                opacity:1;
                transform:translate(0, 0px);
                transition:opacity 0.4s 0s ease;
                //clip-path:url(#clipping);
            }
            .line{
                position:absolute;
                top:4.5px;
                left:0;
                width:100%;
                height:103%;
                //clip-path:url(#clipping2);
                transform:translate(0,20px);
                //overflow:hidden;
                .l,.r{
                    position:absolute;
                    top:0px;
                    width:44px;
                    height:1px;
                    opacity:1;
                }
                .l{
                    transform:translate3d(0,0,0) skewY(-30deg);
                    transform-origin:right top;
                    right:50%;

                    top:0px;
                    background:linear-gradient(270deg, rgba(0,201,255,1) 17%, rgba(146,254,157,1) 100%);
                }
                .r{
                    transform:translate3d(0,0,0) skewY(30deg);
                    transform-origin:left top;
                    left:50%;
                    top:0px;
                    background:linear-gradient(-90deg, rgba(0,201,255,1) 17%, rgba(146,254,157,1) 100%);
                }
            }

        }
        
    }
    .profile-image{
        display:none;
        position:absolute;
        top:50%;
        left:50%;
        width:31%;
        height:31%;
        transform: translate(-50%, -50%);
        z-index:1;
        .hexagon-background{
            height:100%;
            position:absolute;
            clip-path:url(#clipping3);
            height:100%;
            width:100%;
            bottom:0%;
            left:50%;
            opacity:1;
            transform: translate(-50%, 0%);
            background:linear-gradient(45deg, rgba(0,201,255,1) 17%, rgba(146,254,157,1) 100%);
            background:rgba($color-black2-1,1);
            //opacity:0.1;
        }
        .image-container{
            bottom:0%;
            left:50%;
            width:100%;
            height:140%;
            transform: translate(-50%, 0%);
            position:absolute;
            //background:blue;
            clip-path:url(#clipping4);
            &:after{
                //content:"";
                position:absolute;
                bottom:-30%;
                left:50%;
                transform:translate(-50%,0);
                width:350%;
                height:350%;
                
                
                z-index:4;
                background:rgba($color-primary-base,1);
                mask-image: url(../images/stefan6.png);
                mask-mode: alpha;
                mask-size: contain;
                mask-repeat:no-repeat;
                mask-position:center bottom;
                opacity:0.3;
                mix-blend-mode:multiply;
                
                
            }
            &>div{
                bottom:-30%;
                left:50%;
                transform:translate(-50%,0);
                width:350%;
                height:350%;
                max-width:5000%;
                position:absolute;
                opacity:1;
                background-position:center bottom;
                background-image:url(../images/stefan6.png);
                background-size:contain;
                background-repeat: no-repeat;
                
                filter:grayscale(100%);
                //mix-blend-mode: multiply;
                //background-blend-mode: multiply;
                //background-color: rgba($color-primary-base,1);
                
                
            }
        }
        
        
    }
}