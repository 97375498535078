$color-gold:#dac5a7;
$color-gold:#D9BA8D;

$color-primary-base0: #0027F6;
$color-primary-base: #0FE5E6;
$color-primary-base: $color-gold;
//$color-secondary-base: #eb5939;
$color-secondary-base: $color-gold;
$color-white-base: #ffffff;
$color-white-soft: #edeff4;
$color-white-soft2: #B1B3C2;
$color-black:#000;
$color-black0: #07080a;
$color-black-base: #121315;
$color-black2: #202225;
$color-black2-1: #27292E;
$color-black3: #303339;
$color-black4: #5c606e;

$color-blue-soft: #414c60;

$color-font-base: #9f9f9f;
$color-font-dark: #4e4e4c;
$color-font-white: $color-white-base;

$color-font-light:#e2e2e2;
$red: #000000;

$easeInOutExpo: cubic-bezier(0.45, 0, 0.55, 1);
$navHeight: 48px;
$navHeightMobile: 48px;



$color-yellow: #b4bede;
$gradient1-1: #B3FE63;
$gradient1-2: #76FFE3;
$gradient1: linear-gradient(90deg, rgba($gradient1-1,1),  rgba($gradient1-2,1));