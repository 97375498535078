/*@font-face {
    font-family: 'Industry';
    src: url('../fonts/Industry-Bold.woff2') format('woff2');
         font-weight: 700;
         font-display:swap;
         font-style: normal;
}
@font-face {
    font-family: 'Industry';
    src: url('../fonts/Industry-Book.woff2') format('woff2');
         font-weight: 400;
         font-display:swap;
         font-style: normal;
}
@font-face {
    font-family: 'Industry';
    src: url('../fonts/Industry-Light.woff2') format('woff2');
         font-weight: 300;
         font-display:swap;
         font-style: normal;
}
@font-face {
    font-family: 'Industry';
    src: url('../fonts/Industry-Demi.woff2') format('woff2');
         font-weight: 500;
         font-display:swap;
         font-style: normal;
}

@font-face {
    font-family: 'Cera Pro';
    src: url('../fonts/Cera-Pro-Black.woff2') format('woff2');
         font-weight: 800;
         font-display:swap;
         font-style: normal;
}
@font-face {
    font-family: 'Cera Pro';
    src: url('../fonts/Cera-Pro-Bold.woff2') format('woff2');
         font-weight: 700;
         font-display:swap;
         font-style: normal;
}
@font-face {
    font-family: 'Cera Pro';
    src: url('../fonts/Cera-Pro-Medium.woff2') format('woff2');
         font-weight: 500;
         font-display:swap;
         font-style: normal;
}
@font-face {
    font-family: 'Cera Pro';
    src: url('../fonts/Cera-Pro-Regular.woff2') format('woff2');
         font-weight: 400;
         font-display:swap;
         font-style: normal;
}
@font-face {
    font-family: 'Cera Pro';
    src: url('../fonts/Cera-Pro-Light.woff2') format('woff2');
         font-weight: 300;
         font-display:swap;
         font-style: normal;
}
@font-face {
    font-family: 'Cera Pro';
    src: url('../fonts/Cera-Pro-Thin.woff2') format('woff2');
         font-weight: 200;
         font-display:swap;
         font-style: normal;
} */


@font-face {
    font-family: 'ITC Avant Garde Gothic Std2';
    src: url('../fonts/AG-Book.woff2') format('woff2');
         font-weight: 400;
         font-display:swap;
         font-style: normal;
}
@font-face {
    font-family: 'ITC Avant Garde Gothic Std2';
    src: url('../fonts/AG-Medium.woff2') format('woff2');
         font-weight: 500;
         font-display:swap;
         font-style: normal;
}
@font-face {
    font-family: 'ITC Avant Garde Gothic Std2';
    src: url('../fonts/AG-Bold.woff2') format('woff2');
         font-weight: 700;
         font-display:swap;
         font-style: normal;
}
@font-face {
    font-family: 'ITC Avant Garde Gothic Std2';
    src: url('../fonts/AG-Demi.woff2') format('woff2');
         font-weight: 600;
         font-display:swap;
         font-style: normal;
}





//"ITC Avant Garde Gothic Std"
//$font-head: "Industry", Arial, Helvetica, Verdana, sans-serif;
//$font-head2: "Saol Display", Arial, Helvetica, Verdana, sans-serif;
//$font-body: "Cera Pro", Arial, Helvetica, Verdana, sans-serif;

$font-head: "ITC Avant Garde Gothic Std2", Arial, Helvetica, Verdana, sans-serif;
$font-body: "ITC Avant Garde Gothic Std2", Arial, Helvetica, Verdana, sans-serif;


