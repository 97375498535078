.section-skills{
    .columns{
        display:flex;
        flex-flow:row wrap;
        margin:40px auto 60px auto;
        .column{
            flex:0 0 33.333%;
            margin:40px 0 0 0;
            @media only screen and (max-width:$br-tablet-max){
                flex:0 0 50%;
            }
            @media only screen and (max-width:$br-mobile-max){
                flex:0 0 100%;
            }
            ul{
                li{
                    @include fluid-font(14px, 15px);
                    transition:all 0.3s 0s ease;
                    padding:6px 12px 6px 0;
                    line-height:1.4em;
                    &:hover{
                        color:white;
                    }
                }
            }
        }
    }
}